import requestUtils from "../utils/request.utils";

const kycServices = {};

kycServices.index = async () => {
    try {
        return requestUtils.get("/kyc/int", {}, true);
    } catch (err) {
        console.error(err);
    }
};

kycServices.getSignedUrl = async (uuid) => {
    try {
        return requestUtils.get(`/kyc/int/signed-url/${uuid}`, {}, true);
    } catch (err) {
        console.error(err);
    }
};

kycServices.getSumsubAccessToken = async () => {
    try {
        return requestUtils.get("/kyc/sumsub/token", {}, true);
    } catch (err) {
        console.error(err);
    }
};

kycServices.createInternalKyc = async (data) => {
    try {
        return requestUtils.post("/kyc/int", data, true);
    } catch (err) {
        console.error(err);
    }
};

kycServices.deleteKycDocument = async (uuid) => {
    try {
        return requestUtils.delete(`/kyc/int/${uuid}`, {}, true);
    } catch (err) {
        console.error(err);
    }
};

export default kycServices;
