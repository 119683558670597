import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import validator from "validator/es";
import { Tooltip } from "react-tooltip";
import { add, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate, T } from "@tolgee/react";
import DatePicker from "react-datepicker";

import helpersUtils from "../../utils/helpers.utils";
import usersServices from "../../services/users.services";
import projectsServices from "../../services/projects.services";
import countriesServices from "../../services/countries.services";
import StyledSelect from "../../components/ui/StyledSelect";
import localesProjectsServices from "../../services/locales_projects.services";

const AccountProfile = () => {
    const { t } = useTranslate();
    const [fields, setFields] = useState({});
    const [profileRequiredFields, setProfileRequiredFields] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [localesProjectsList, setLocalesProjectsList] = useState([]);
    const [completedFields, setCompletedFields] = useState([]);
    const [errors, setErrors] = useState({});
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    useEffect(() => {
        const requests = [
            projectsServices.profileRequiredFields(),
            usersServices.info(),
            countriesServices.index(),
            localesProjectsServices.index(),
        ];
        Promise.all(requests).then(
            ([requiredFields, profileData, countries, localesProjects]) => {
                requiredFields && setProfileRequiredFields(requiredFields);

                if (profileData.birth_date) {
                    profileData.birth_date = formatInTimeZone(
                        new Date(profileData.birth_date),
                        "UTC",
                        "dd/MM/yyyy",
                    );
                    //profileData.birth_date = format(new Date(profileData.birth_date),'dd/MM/yyyy');
                }
                setFields(profileData);

                checkCompletedFields(profileData);

                countries && setCountriesList(countries);
                localesProjects && setLocalesProjectsList(localesProjects);
            },
        );
    }, []);

    useEffect(() => {
        checkCompletedFields(userData);
    }, [userData]);

    const checkCompletedFields = (profileData) => {
        setCompletedFields(
            Object.keys(profileData).filter((field) => {
                return !!profileData[field];
            }),
        );
    };

    const handleChange = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);
        const newFields = { ...fields };

        newFields[name] =
            target.dataset.lowercase === "true" ? value.toLowerCase() : value;

        setFields(newFields);
        setErrors({ ...errors, [name]: undefined });
    };

    const handleBlur = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);

        const newFields = { ...fields };
        const newErrors = { ...errors };

        newFields[name] = target.dataset.trim === "true" ? value.trim() : value;

        validatorFields[name] &&
            validatorFields[name](
                newFields,
                newErrors,
                t,
                profileRequiredFields.includes(name),
            );

        setErrors(newErrors);
        setFields(newFields);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newFields = { ...fields };

        const newErrors = validate(
            newFields,
            profileRequiredFields,
            completedFields,
            t,
            {},
        );
        setErrors(newErrors);

        if (Object.keys(newErrors).length !== 0) return;

        const filteredFields = {};

        const allowedUpdateFields = ["phone", "nickname"];
        Object.keys(newFields).forEach((field) => {
            if (
                allowedUpdateFields.includes(field) ||
                !completedFields.includes(field)
            ) {
                filteredFields[field] = newFields[field];
            }
        });

        if (filteredFields.birth_date) {
            filteredFields.birth_date = format(
                filteredFields.birth_date,
                "yyyy-MM-dd",
            );
        }
        const success = await usersServices.edit(filteredFields);

        if (success) {
            toast(
                t(
                    "account.profile.profile_updated_message",
                    "Profile updated successfully",
                ),
            );
            usersServices.updateUserData(userData, dispatch);
        }
    };

    //if (profileRequiredFields.length === 0 || countriesList.length === 0) return null;
    if (countriesList.length === 0) return null;

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2>
                                <T keyName="account.profile.prodile_title">
                                    Profile
                                </T>
                            </h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <div className="account__body">
                                <div className="account__shape account__shape--medium">
                                    <form
                                        className="form account__form"
                                        onSubmit={handleSubmit}
                                    >
                                        <fieldset>
                                            {/*<div className="account__img-box">
                                                <div className="account__img-current">
                                                    <img className="account__img" src="img/avatar/ava_01.jpg" alt=""/>
                                                </div>
                                                <div className="account__img-gallery">
                                                    <div className="account__img-gallery-body">
                                                        <div className="account__img-gallery-close">
                                                            <div className="btn-close">
                                                                <img src="img/icons/icons.svg#ico_close" alt=""
                                                                     className="btn-close__img"/>
                                                            </div>
                                                        </div>
                                                        <div className="account__shape">
                                                            <ul className="account__img-list">
                                                                <li className="account__img-item account__img-item--current">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                                <li className="account__img-item">
                                                                    <img className="account__img"
                                                                         src="img/avatar/ava_01.jpg"
                                                                         alt=""/>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            <div className="account__form-row">
                                                <div className="account__shape">
                                                    <label
                                                        className={`input-area ${errors.name ? "input-area--error" : ""} ${completedFields.includes("name") ? "input-area--completed" : ""}`}
                                                        data-label={t(
                                                            "person.name",
                                                        )}
                                                        data-error={
                                                            errors.name || ""
                                                        }
                                                    >
                                                        <input
                                                            name="name"
                                                            autoComplete="given-name"
                                                            placeholder={t(
                                                                "person.name",
                                                            )}
                                                            autoFocus
                                                            type="text"
                                                            className="input-area__input"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                                fields.name ||
                                                                ""
                                                            }
                                                            data-lowercase={
                                                                false
                                                            }
                                                            data-trim={true}
                                                            tabIndex={1}
                                                            disabled={completedFields.includes(
                                                                "name",
                                                            )}
                                                        />
                                                    </label>
                                                    <label
                                                        className={`input-area ${errors.surname ? "input-area--error" : ""} ${completedFields.includes("surname") ? "input-area--completed" : ""}`}
                                                        data-label={`${helpersUtils.capitalizeFirstLetter(t("person.surname", "surname"))}:`}
                                                        data-error={
                                                            errors.surname || ""
                                                        }
                                                    >
                                                        <input
                                                            name="surname"
                                                            autoComplete="family-name"
                                                            placeholder={helpersUtils.capitalizeFirstLetter(
                                                                t(
                                                                    "person.surname",
                                                                    "surname",
                                                                ),
                                                            )}
                                                            type="text"
                                                            className="input-area__input"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                                fields.surname ||
                                                                ""
                                                            }
                                                            data-lowercase={
                                                                false
                                                            }
                                                            data-trim={true}
                                                            tabIndex={2}
                                                            disabled={completedFields.includes(
                                                                "surname",
                                                            )}
                                                        />
                                                    </label>
                                                    <div className="form__row">
                                                        <StyledSelect
                                                            title={helpersUtils.capitalizeFirstLetter(
                                                                t(
                                                                    "person.country",
                                                                    "country",
                                                                ),
                                                            )}
                                                            fieldName="country_id"
                                                            selectedValue={
                                                                fields.country_id
                                                            }
                                                            options={countriesList.map(
                                                                (country) => {
                                                                    return {
                                                                        value: country.id,
                                                                        label: country.name,
                                                                    };
                                                                },
                                                            )}
                                                            setValue={(
                                                                value,
                                                            ) => {
                                                                setFields({
                                                                    ...fields,
                                                                    country_id:
                                                                        value,
                                                                });
                                                                setErrors({
                                                                    ...errors,
                                                                    currency_id:
                                                                        undefined,
                                                                });
                                                            }}
                                                            error={
                                                                errors.country_id
                                                            }
                                                            extraClasses={
                                                                completedFields.includes(
                                                                    "country_id",
                                                                )
                                                                    ? "input-area--completed"
                                                                    : ""
                                                            }
                                                            tabIndex={3}
                                                        />
                                                        <label
                                                            className={`input-area ${errors.city ? "input-area--error" : ""} ${completedFields.includes("city") ? "input-area--completed" : ""}`}
                                                            data-label={`${helpersUtils.capitalizeFirstLetter(t("person.city", "city"))}:`}
                                                            data-error={
                                                                errors.city ||
                                                                ""
                                                            }
                                                        >
                                                            <input
                                                                name="city"
                                                                autoComplete="address-level2"
                                                                placeholder={helpersUtils.capitalizeFirstLetter(
                                                                    t(
                                                                        "person.city",
                                                                        "city",
                                                                    ),
                                                                )}
                                                                type="text"
                                                                className="input-area__input"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    fields.city ||
                                                                    ""
                                                                }
                                                                data-lowercase={
                                                                    false
                                                                }
                                                                data-trim={true}
                                                                tabIndex={4}
                                                            />
                                                        </label>
                                                    </div>
                                                    <label
                                                        className={`input-area ${errors.address ? "input-area--error" : ""} ${completedFields.includes("address") ? "input-area--completed" : ""}`}
                                                        data-label={`${helpersUtils.capitalizeFirstLetter(t("person.address", "address"))}:`}
                                                        data-error={
                                                            errors.address || ""
                                                        }
                                                    >
                                                        <input
                                                            name="address"
                                                            autoComplete="street-address"
                                                            placeholder={helpersUtils.capitalizeFirstLetter(
                                                                t(
                                                                    "person.address",
                                                                    "address",
                                                                ),
                                                            )}
                                                            type="text"
                                                            className="input-area__input"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                                fields.address ||
                                                                ""
                                                            }
                                                            data-lowercase={
                                                                false
                                                            }
                                                            data-trim={true}
                                                            tabIndex={5}
                                                            disabled={completedFields.includes(
                                                                "address",
                                                            )}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="account__shape">
                                                    {/*<label className="input-area input-area--completed"
                                                           data-label="Телефон:"
                                                           data-error="Телефон:">
                                                        <input type="text" className="input-area__input"
                                                               placeholder="Телефон"
                                                               value="123456789" />
                                                            <div className="input-drop-down input-drop-down--small">
                                                                <div className="input-drop-down__current">
                                                                    <img className="input-drop-down__img"
                                                                         src="img/icons/icons.svg#flag_ru" alt=""/>
                                                                    <span className="input-drop-down__info">+373</span>
                                                                </div>
                                                                <ul className="input-drop-down__list">
                                                                    <li className="input-drop-down__item">
                                                                        <label className="input-drop-down__label">
                                                                            <input type="radio" name="country" value="1"
                                                                                   className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src="img/icons/icons.svg#flag_en" alt=""/>
																		<span
                                                                            className="input-drop-down__info">+488</span>
																	</span>
                                                                        </label>
                                                                    </li>
                                                                    <li className="input-drop-down__item">
                                                                        <label className="input-drop-down__label">
                                                                            <input type="radio" name="country" value="2"
                                                                                   className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src="img/icons/icons.svg#flag_de" alt=""/>
																		<span
                                                                            className="input-drop-down__info">+656</span>
																	</span>
                                                                        </label>
                                                                    </li>
                                                                    <li className="input-drop-down__item">
                                                                        <label className="input-drop-down__label">
                                                                            <input type="radio" name="country" value="3"
                                                                                   className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src="img/icons/icons.svg#flag_pl" alt=""/>
																		<span
                                                                            className="input-drop-down__info">+002</span>
																	</span>
                                                                        </label>
                                                                    </li>
                                                                    <li className="input-drop-down__item">
                                                                        <label className="input-drop-down__label">
                                                                            <input type="radio" name="country" value="4"
                                                                                   className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src="img/icons/icons.svg#flag_ru" alt=""/>
																		<span
                                                                            className="input-drop-down__info">+031</span>
																	</span>
                                                                        </label>
                                                                    </li>
                                                                    <li className="input-drop-down__item">
                                                                        <label className="input-drop-down__label">
                                                                            <input type="radio" name="country" value="5"
                                                                                   className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src="img/icons/icons.svg#flag_ru" alt=""/>
																		<span
                                                                            className="input-drop-down__info">+048</span>
																	</span>
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                    </label>*/}
                                                    <div className="form__row">
                                                        <label
                                                            className={`input-area ${errors.phone ? "input-area--error" : ""}`}
                                                            data-label={t(
                                                                "person.phone",
                                                            )}
                                                            data-error={
                                                                errors.phone ||
                                                                ""
                                                            }
                                                            data-tooltip-id="phone"
                                                            data-tooltip-html="Phone number in international format starting with <b>+</b><br />Example: <b>+393471223344</b>"
                                                        >
                                                            <input
                                                                name="phone"
                                                                autoComplete="tel"
                                                                placeholder={t(
                                                                    "person.phone",
                                                                )}
                                                                type="text"
                                                                className="input-area__input"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    fields.phone ||
                                                                    ""
                                                                }
                                                                data-lowercase={
                                                                    false
                                                                }
                                                                data-trim={true}
                                                                tabIndex={6}
                                                            />
                                                        </label>
                                                        {!completedFields[
                                                            "phone"
                                                        ] && (
                                                            <Tooltip id="phone" />
                                                        )}
                                                        <StyledSelect
                                                            title={helpersUtils.capitalizeFirstLetter(
                                                                t(
                                                                    "person.locale",
                                                                    "locale",
                                                                ),
                                                            )}
                                                            fieldName="locale_id"
                                                            selectedValue={
                                                                fields.locale_id
                                                            }
                                                            options={localesProjectsList.map(
                                                                (
                                                                    localeProject,
                                                                ) => {
                                                                    return {
                                                                        value: localeProject.locale_id,
                                                                        label: `${localeProject.name} (${localeProject.locale})`,
                                                                    };
                                                                },
                                                            )}
                                                            setValue={(
                                                                value,
                                                            ) => {
                                                                setFields({
                                                                    ...fields,
                                                                    locale_id:
                                                                        value,
                                                                });
                                                                setErrors({
                                                                    ...errors,
                                                                    locale_id:
                                                                        undefined,
                                                                });
                                                            }}
                                                            error={
                                                                errors.locale_id
                                                            }
                                                            tabIndex={3}
                                                        />
                                                    </div>
                                                    <label
                                                        className={`input-area input-area--completed`}
                                                        data-label={`${helpersUtils.capitalizeFirstLetter(t("person.email", "email"))}:`}
                                                        data-error={
                                                            errors.email || ""
                                                        }
                                                    >
                                                        <input
                                                            name="email"
                                                            autoComplete="email"
                                                            placeholder={helpersUtils.capitalizeFirstLetter(
                                                                t(
                                                                    "person.email",
                                                                    "email",
                                                                ),
                                                            )}
                                                            type="text"
                                                            className="input-area__input"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                                fields.email ||
                                                                ""
                                                            }
                                                            data-lowercase={
                                                                false
                                                            }
                                                            data-trim={true}
                                                            tabIndex={7}
                                                            disabled={true}
                                                        />
                                                    </label>
                                                    <div className="form__row">
                                                        <label
                                                            className={`input-area ${errors.zip ? "input-area--error" : ""} ${completedFields.includes("zip") ? "input-area--completed" : ""}`}
                                                            data-label={`${helpersUtils.capitalizeFirstLetter(t("person.zip", "zip"))}:`}
                                                            data-error={
                                                                errors.zip || ""
                                                            }
                                                        >
                                                            <input
                                                                name="zip"
                                                                autoComplete="postal-code"
                                                                placeholder={helpersUtils.capitalizeFirstLetter(
                                                                    t(
                                                                        "person.zip",
                                                                        "zip",
                                                                    ),
                                                                )}
                                                                type="text"
                                                                className="input-area__input"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    fields.zip ||
                                                                    ""
                                                                }
                                                                data-lowercase={
                                                                    false
                                                                }
                                                                data-trim={true}
                                                                tabIndex={8}
                                                                disabled={completedFields.includes(
                                                                    "zip",
                                                                )}
                                                            />
                                                        </label>
                                                        <StyledSelect
                                                            title={helpersUtils.capitalizeFirstLetter(
                                                                t(
                                                                    "person.gender",
                                                                    "gender",
                                                                ),
                                                            )}
                                                            fieldName="gender"
                                                            selectedValue={
                                                                fields.gender
                                                            }
                                                            options={[
                                                                {
                                                                    value: "m",
                                                                    label: t(
                                                                        "person.gender_male",
                                                                        "Male",
                                                                    ),
                                                                },
                                                                {
                                                                    value: "f",
                                                                    label: t(
                                                                        "person.gender_female",
                                                                        "Female",
                                                                    ),
                                                                },
                                                            ]}
                                                            setValue={(
                                                                value,
                                                            ) => {
                                                                setFields({
                                                                    ...fields,
                                                                    gender: value,
                                                                });
                                                                setErrors({
                                                                    ...errors,
                                                                    gender: undefined,
                                                                });
                                                            }}
                                                            error={
                                                                errors.gender
                                                            }
                                                            extraClasses={
                                                                completedFields.includes(
                                                                    "gender",
                                                                )
                                                                    ? "input-area--completed"
                                                                    : ""
                                                            }
                                                            tabIndex={9}
                                                        />
                                                    </div>
                                                    <div className="form__row">
                                                        <div>
                                                            <label
                                                                className="input-area__label-datepicker"
                                                                htmlFor="birth_date"
                                                            >
                                                                <T keyName="person.birth_date">
                                                                    Birth date
                                                                </T>
                                                                :
                                                            </label>
                                                            <DatePicker
                                                                className={`input-area ${errors.birth_date ? "input-area--error" : ""} ${completedFields.includes("birth_date") ? "input-area--completed" : ""}`}
                                                                selected={
                                                                    fields.birth_date ||
                                                                    ""
                                                                }
                                                                name="birth_date"
                                                                id="birth_date"
                                                                placeholderText={helpersUtils.capitalizeFirstLetter(
                                                                    t(
                                                                        "person.birth_date",
                                                                        "birth date",
                                                                    ),
                                                                )}
                                                                onChange={(
                                                                    date,
                                                                ) => {
                                                                    const newFields =
                                                                        {
                                                                            ...fields,
                                                                        };
                                                                    newFields[
                                                                        "birth_date"
                                                                    ] = date;
                                                                    setFields(
                                                                        newFields,
                                                                    );
                                                                }}
                                                                disabled={completedFields.includes(
                                                                    "birth_date",
                                                                )}
                                                                maxDate={add(
                                                                    new Date(),
                                                                    {
                                                                        years: -18,
                                                                    },
                                                                )}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                shouldCloseOnSelect
                                                                customInput={
                                                                    <input
                                                                        data-tooltip-id="birth_date"
                                                                        data-tooltip-html="Birth date <b>DD/MM/YYYY</b>"
                                                                    />
                                                                }
                                                            />
                                                            {!completedFields[
                                                                "birth_date"
                                                            ] && (
                                                                <Tooltip id="birth_date" />
                                                            )}
                                                        </div>
                                                        <label
                                                            className={`input-area ${errors.nickname ? "input-area--error" : ""}`}
                                                            data-label={`${helpersUtils.capitalizeFirstLetter(t("person.nickname", "nickname"))}:`}
                                                            data-error={
                                                                errors.nickname ||
                                                                ""
                                                            }
                                                        >
                                                            <input
                                                                name="nickname"
                                                                autoComplete="nickname"
                                                                placeholder={helpersUtils.capitalizeFirstLetter(
                                                                    t(
                                                                        "person.nickname",
                                                                        "nickname",
                                                                    ),
                                                                )}
                                                                type="text"
                                                                className="input-area__input"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={
                                                                    fields.nickname ||
                                                                    ""
                                                                }
                                                                data-lowercase={
                                                                    false
                                                                }
                                                                data-trim={true}
                                                                tabIndex={11}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-row">
                                                <label className="btn btn--medium">
                                                    <input
                                                        type="submit"
                                                        tabIndex={12}
                                                    />
                                                    <span>
                                                        {t(
                                                            "person.save_button",
                                                            "Save",
                                                        )}
                                                    </span>
                                                </label>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

const validatorFields = {
    name: (fields, errors, t, required = true) => {
        if (required && !fields.name) {
            return (errors.name = t(
                "account.profile.insert_name_error",
                "Please insert name",
            ));
        }
    },
    surname: (fields, errors, t, required = true) => {
        if (required && !fields.surname) {
            return (errors.surname = t(
                "account.profile.insert_surname_error",
                "Please insert surname",
            ));
        }
    },
    country_id: (fields, errors, t, required = true) => {
        if (required && !fields.country_id) {
            return (errors.country_id = t(
                "account.profile.select_country_error",
                "Please select a country",
            ));
        }
    },
    locale_id: (fields, errors, t, required = true) => {
        if (required && !fields.locale_id) {
            return (errors.locale_id = t(
                "account.profile.select_locale_error",
                "Please select a locale",
            ));
        }
    },
    region: (fields, errors, t, required = true) => {
        if (required && !fields.region) {
            return (errors.region = t(
                "account.profile.insert_region_error",
                "Please insert region",
            ));
        }
    },
    city: (fields, errors, t, required = true) => {
        if (required && !fields.city) {
            return (errors.city = t(
                "account.profile.insert_city_error",
                "Please insert city",
            ));
        }
    },
    address: (fields, errors, t, required = true) => {
        if (required && !fields.address) {
            return (errors.address = t(
                "account.profile.insert_address_error",
                "Please insert address",
            ));
        }
    },
    zip: (fields, errors, t, required = true) => {
        if (required && !fields.zip) {
            return (errors.zip = t(
                "account.profile.insert_zip_code_error",
                "Please insert zip code",
            ));
        }
    },
    birth_date: (fields, errors, t, required = true) => {
        if (!required && !fields.birth_date) return;
        if (!validator.isDate(fields.birth_date, { format: "DD/MM/YYYY" })) {
            return (errors.birth_date = t(
                "account.profile.insert_valid_birthdate_error",
                "Please insert a valid birthdate",
            ));
        }
    },
    gender: (fields, errors, t, required = true) => {
        if (!required && !fields.gender) return;
        if (!validator.isIn(fields.gender || "", ["m", "f"])) {
            return (errors.gender = t(
                "account.profile.select_gender_error",
                "Please select gender",
            ));
        }
    },
    phone: (fields, errors, t, required = true) => {
        if (!required && !fields.phone) return;
        if (
            !validator.isMobilePhone(fields.phone, "any", { strictMode: true })
        ) {
            return (errors.phone = t(
                "account.profile.valid_phone_number_error",
                "Please insert a valid mobile phone number",
            ));
        }
    },
    nickname: (fields, errors, t, required = true) => {
        if (required && !fields.nickname) {
            return (errors.nickname = t(
                "account.profile.insert_nickname_error",
                "Please select a nickname",
            ));
        }
    },
};
const validate = (fields, requiredFields, completedFields, t, errors = {}) => {
    !completedFields.includes("name") &&
        validatorFields.name(
            fields,
            errors,
            t,
            requiredFields.includes("name"),
        );
    !completedFields.includes("surname") &&
        validatorFields.surname(
            fields,
            errors,
            t,
            requiredFields.includes("surname"),
        );
    !completedFields.includes("country_id") &&
        validatorFields.country_id(
            fields,
            errors,
            t,
            requiredFields.includes("country_id"),
        );
    !completedFields.includes("locale_id") &&
        validatorFields.locale_id(
            fields,
            errors,
            t,
            requiredFields.includes("locale_id"),
        );
    !completedFields.includes("region") &&
        validatorFields.region(
            fields,
            errors,
            t,
            requiredFields.includes("region"),
        );
    !completedFields.includes("city") &&
        validatorFields.city(
            fields,
            errors,
            t,
            requiredFields.includes("city"),
        );
    !completedFields.includes("address") &&
        validatorFields.address(
            fields,
            errors,
            t,
            requiredFields.includes("address"),
        );
    !completedFields.includes("zip") &&
        validatorFields.zip(fields, errors, t, requiredFields.includes("zip"));
    !completedFields.includes("birth_date") &&
        validatorFields.birth_date(
            fields,
            errors,
            t,
            requiredFields.includes("birth_date"),
        );
    !completedFields.includes("gender") &&
        validatorFields.gender(
            fields,
            errors,
            t,
            requiredFields.includes("gender"),
        );
    validatorFields.phone(fields, errors, t, requiredFields.includes("phone"));
    validatorFields.nickname(
        fields,
        errors,
        t,
        requiredFields.includes("nickname"),
    );
    return errors;
};

export default AccountProfile;
