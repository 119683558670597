export const documentStatuses = [
    {
        value: "PENDING",
        title: "Pending",
        translationKey: "account.verification.status.pending",
    },
    {
        value: "ACCEPTED",
        title: "Accepted",
        translationKey: "account.verification.status.accepted",
    },
    {
        value: "EXPIRED",
        title: "Expired",
        translationKey: "account.verification.status.expired",
    },
];
