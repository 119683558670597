import React, { useState, useEffect } from "react";
import { Lightbox } from "react-modal-image";

const ImageViewer = ({ imageUrl }) => {
    const [isOpen, setIsOpen] = useState(false);

    // When imageUrl becomes available, automatically open the lightbox.
    useEffect(() => {
        if (imageUrl) {
            setIsOpen(true);
        }
    }, [imageUrl]);

    return (
        <>
            {isOpen && imageUrl && (
                <Lightbox
                    large={imageUrl}
                    alt=""
                    onClose={() => setIsOpen(false)}
                    hideDownload={true}
                    hideZoom={true}
                    showRotate={false}
                />
            )}
        </>
    );
};

export default ImageViewer;
