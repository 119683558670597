import requestUtils from "../utils/request.utils";

const localesProjectsServices = {};

localesProjectsServices.index = async () => {
    try {
        return requestUtils.get("/locales_projects", {}, true);
    } catch (err) {
        console.error(err);
    }
};
export default localesProjectsServices;
