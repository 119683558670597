import React, { useEffect, useState } from "react";

import AccountVerification from "./AccountVerification";
import AccountVerificationSumSub from "./AccountVerificationSumSub";

const kycTypes = ["SUMSUB", "INTERNAL"];

export const AccountKycVerification = () => {
    const [kycType, setKycType] = useState("INTERNAL");

    useEffect(() => {
        return () => setKycType("INTERNAL");
    }, []);

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <li className={`main-nav__item`}>
                                <div
                                    onClick={
                                        () => setKycType("INTERNAL")
                                        // dispatch(
                                        //     setShowMobileMenu(false),
                                        // )
                                    }
                                    className={`main-nav__link ${kycType === "INTERNAL" ? "main-nav__link--active" : ""}`}
                                >
                                    <span className="main-nav__info">
                                        Internal
                                    </span>
                                </div>
                            </li>
                        </div>
                        <div className="heading__content">
                            <li className={`main-nav__item`}>
                                <div
                                    onClick={
                                        () => setKycType("SUMSUB")
                                        // dispatch(
                                        //     setShowMobileMenu(false),
                                        // )
                                    }
                                    className={`main-nav__link ${kycType === "SUMSUB" ? "main-nav__link--active" : ""}`}
                                >
                                    <span className="main-nav__info">
                                        Sumsub
                                    </span>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
                {kycType === "INTERNAL" ? (
                    <AccountVerification />
                ) : (
                    <AccountVerificationSumSub />
                )}
            </section>
        </main>
    );
};
