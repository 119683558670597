import React, { useEffect, useState } from "react";
import { T, useTranslate } from "@tolgee/react";

import { s3Config } from "../../config/aws.config";
import SmallButton from "../../components/ui/SmallButton";
import kycServices from "../../services/kyc.services";
import { toast } from "react-toastify";
import ImageViewer from "../../components/ImageViewer";
import { documentTypes } from "../../constants/documentTypes";
import { documentStatuses } from "../../constants/documentStatuses";
import { useDetectClickOutside } from "react-detect-click-outside";

const imgUrl = `${s3Config.assetsUrl}/img`;
// Placeholder Dataformat
// 'ru-RU'
const formattedDate = (timestampValue, locale) => {
    const lastModifiedDate = new Date(timestampValue);
    const timeString = lastModifiedDate.toLocaleTimeString(locale, {
        hour12: false,
    });
    const dateString = lastModifiedDate.toLocaleDateString(locale);
    return `${timeString} / ${dateString}`;
};

const AccountVerification = () => {
    const { t } = useTranslate();
    const [isVerified] = useState(false);
    const [documentTypeLabel, setDocumentTypeLabel] = useState(null);
    const [documentTypeTranslation, setDocumentTypeTranslation] =
        useState(null);
    const [dropdownActive, setDropdownActive] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [refreshDocuments, setRefreshDocuments] = useState(0);
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});

    const handleFileChange = (event) => {
        setFields((prev) => {
            return { ...prev, document: event.target.files[0] };
        });
        setErrors((prev) => {
            return { ...prev, document: false };
        });
    };

    const closeDropDown = () => {
        setDropdownActive(false);
    };
    const ref = useDetectClickOutside({ onTriggered: closeDropDown });

    const validateDropDown = () => {
        if (!fields.documentType) {
            setErrors((prev) => {
                return {
                    ...prev,
                    documentType: t(
                        "account.verification.insert_document_type",
                        "Please select document type",
                    ),
                };
            });
        }
    };

    const handleDocumentTypeChange = (el) => {
        setDocumentTypeLabel(el.title);
        setDocumentTypeTranslation(el.translationKey);
        closeDropDown();
        const newFields = { ...fields, documentType: el.value };
        const newErrors = { ...errors, documentType: undefined };
        setFields(newFields);
        setErrors(newErrors);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!fields.document) {
            handleFileBlur();
        }

        if (!fields.documentType) {
            validateDropDown();
        }

        if (fields.document && fields.documentType) {
            setLoading(true);
            const formData = new FormData();
            formData.append("document", fields.document);
            formData.append("type", fields.documentType);

            kycServices
                .createInternalKyc(formData)
                .then(() => {
                    setRefreshDocuments((prev) => ++prev);
                })
                .catch((err) => {
                    console.error(err, "err");
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        kycServices.index().then((kycDocuments) => {
            setDocuments(kycDocuments.data);
            setLoading(false);
        });
    }, [refreshDocuments]);

    const handleShowDocument = (uuid) => {
        kycServices.getSignedUrl(uuid).then(({ url }) => {
            setImageUrl(url);
        });
    };

    const handleDeleteDocument = (document) => {
        setLoading(true);
        if (document.status !== "PENDING") {
            toast.error(
                t(
                    "account.casino.verification.cannot_be_deleted",
                    "Only pending status documents can be deleted.",
                ),
            );
            return;
        }

        if (
            !window.confirm(
                t(
                    "account.casino.verification.delete_document",
                    "Are you sure you want to delete this document?",
                ),
            )
        ) {
            setLoading(false);
            return;
        }

        kycServices
            .deleteKycDocument(document.uuid)
            .then(() => {
                setRefreshDocuments((prev) => ++prev);
                toast.success(
                    t(
                        "account.casino.verification.success_deleted",
                        "Document successfully deleted.",
                    ),
                );
            })
            .catch((e) => console.log("deleteError", e));
    };

    const handleFileBlur = () => {
        if (!fields.document) {
            setErrors((prev) => {
                return {
                    ...prev,
                    document: t(
                        "account.verification.insert_document",
                        "Please upload document",
                    ),
                };
            });
        }
    };

    return (
        <div className="account">
            <div className="account__grid">
                {isVerified ? (
                    <div className="account__body">
                        <div className="account__shape">
                            <div className="allert-message allert-message--center">
                                <img
                                    src={`${imgUrl}/icons/icons.svg#ico_verify`}
                                    className="allert-message__img"
                                    alt="ico_verify"
                                />
                                <span>
                                    <T keyName="account.verification.user_verified">
                                        User verified
                                    </T>
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="account__body">
                        <div className="account__shape account__shape--large">
                            <form
                                className="form account__form"
                                onSubmit={handleSubmit}
                            >
                                <fieldset>
                                    <div className="account__form-row">
                                        <div
                                            ref={ref}
                                            className={`input-area ${errors.documentType ? "input-area--error" : ""}`}
                                            data-label={t(
                                                "account.verification.document_type",
                                                "Document type",
                                            )}
                                            data-error={errors.documentType}
                                        >
                                            <div
                                                className={`input-drop-down ${dropdownActive ? "input-drop-down--active" : null}`}
                                            >
                                                <div
                                                    className="input-drop-down__current"
                                                    onClick={() => {
                                                        setDropdownActive(
                                                            (prev) => !prev,
                                                        );
                                                    }}
                                                >
                                                    {documentTypeLabel
                                                        ? t(
                                                              documentTypeTranslation,
                                                              documentTypeLabel,
                                                          )
                                                        : t(
                                                              "account.verification.document_type",
                                                              "Document type",
                                                          )}
                                                </div>
                                                <ul className="input-drop-down__list">
                                                    {documentTypes.map((el) => {
                                                        return (
                                                            <li
                                                                key={el.value}
                                                                className="input-drop-down__item"
                                                                onClick={() =>
                                                                    handleDocumentTypeChange(
                                                                        el,
                                                                    )
                                                                }
                                                            >
                                                                <label className="input-drop-down__label">
                                                                    <input
                                                                        type="radio"
                                                                        name="documentType"
                                                                        value={
                                                                            el.value
                                                                        }
                                                                        className="input-drop-down__radio"
                                                                    />
                                                                    <span className="input-drop-down__content">
                                                                        <span className="input-drop-down__info">
                                                                            {t(
                                                                                el.translationKey,
                                                                                el.title,
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <label
                                            className={`input-area ${errors.document ? "input-area--error" : ""} input-area--copy`}
                                            data-label={t(
                                                "account.verification.select_file",
                                                "Please select a file",
                                            )}
                                            data-error={errors.document}
                                        >
                                            <input
                                                type="file"
                                                className="input-area__input"
                                                onChange={handleFileChange}
                                                onBlur={handleFileBlur}
                                                accept="image/*,.png,.jpg,applocation/pdf"
                                            />
                                            <div className="input-area__placeholder">
                                                {fields.document
                                                    ? fields.document.name
                                                    : t(
                                                          "account.verification.select_file",
                                                          "Please select a file",
                                                      )}
                                            </div>
                                            <div className="input-area__img-box">
                                                <img
                                                    src={`${imgUrl}/icons/icons.svg#ico_browse`}
                                                    className="svg-ico_browse-dims"
                                                    alt="ico_browse"
                                                />
                                            </div>
                                        </label>
                                        <button
                                            disabled={loading}
                                            type="submit"
                                            className="btn btn--blue"
                                            onClick={(event) =>
                                                handleSubmit(event)
                                            }
                                        >
                                            <span>
                                                <T keyName="account.verification.upload">
                                                    Upload
                                                </T>
                                            </span>
                                        </button>
                                    </div>
                                </fieldset>
                            </form>
                            <div className="table-container">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <T keyName="account.verification.document_type">
                                                    Document type
                                                </T>
                                            </th>
                                            <th>
                                                <T keyName="account.verification.status">
                                                    Status
                                                </T>
                                            </th>
                                            <th>
                                                <T keyName="account.verification.download_type">
                                                    Created time
                                                </T>
                                            </th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {documents.map((document) => {
                                            const documentType =
                                                documentTypes.find(
                                                    ({ value }) =>
                                                        value === document.type,
                                                );
                                            const documentStatus =
                                                documentStatuses.find(
                                                    ({ value }) =>
                                                        value ===
                                                        document.status,
                                                );
                                            return (
                                                <tr key={document.uuid}>
                                                    <td>
                                                        <T
                                                            keyName={
                                                                documentType.translationKey
                                                            }
                                                        >
                                                            {documentType.title}
                                                        </T>
                                                    </td>
                                                    <td>
                                                        <T
                                                            keyName={
                                                                documentStatus.translationKey
                                                            }
                                                        >
                                                            {
                                                                documentStatus.title
                                                            }
                                                        </T>
                                                    </td>
                                                    <td>
                                                        {formattedDate(
                                                            document.created_at,
                                                            "ru-RU",
                                                        )}
                                                    </td>
                                                    <td>
                                                        <SmallButton
                                                            black
                                                            onClick={() =>
                                                                handleShowDocument(
                                                                    document.uuid,
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                <T keyName="account.verification.show">
                                                                    Show
                                                                </T>
                                                            </span>
                                                        </SmallButton>
                                                    </td>
                                                    <td>
                                                        {document.status ===
                                                        "PENDING" ? (
                                                            <SmallButton
                                                                onClick={() =>
                                                                    handleDeleteDocument(
                                                                        document,
                                                                    )
                                                                }
                                                            >
                                                                <span>
                                                                    <T keyName="account.casino.bonuses.activate_button">
                                                                        Delete
                                                                    </T>
                                                                </span>
                                                            </SmallButton>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <>
                                <p>
                                    <T keyName="account.verification.upload_identification_document">
                                        Dear user, to verify your account you
                                        need to upload an identification
                                        document.
                                    </T>
                                </p>
                                <p className="bold">
                                    <T keyName="account.verification.upload_criteria">
                                        When uploading, make sure it meets the
                                        following criteria:
                                    </T>
                                </p>
                                <ul className="dot-list">
                                    <li>
                                        <T keyName="account.verification.upload_criteria_1">
                                            The file is in JPG, PNG or GIF
                                            format and does not exceed 10 MB.
                                        </T>
                                    </li>
                                    <li>
                                        <T keyName="account.verification.upload_criteria_2">
                                            The document has an expiration date.
                                        </T>
                                    </li>
                                    <li>
                                        <T keyName="account.verification.upload_criteria_3">
                                            The picture must be in real colors,
                                            not black and white.
                                        </T>
                                    </li>
                                    <li>
                                        <T keyName="account.verification.upload_criteria_4">
                                            The photograph must be taken from
                                            the original document; digital photo
                                            editing is not permitted.
                                        </T>
                                    </li>
                                </ul>
                            </>
                        </div>
                    </div>
                )}
            </div>
            <ImageViewer imageUrl={imageUrl} />
        </div>
    );
};

export default AccountVerification;
