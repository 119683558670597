import { toast } from "react-toastify";
import queryString from "qs";

const requestUtils = {};

const defaultOptions = {
    credentials: "same-origin",
    headers: {
        Accept: "application/json",
    },
};
const defaultUrl = "/api";

requestUtils.get = async (path, params = {}, displayError = false) => {
    const options = { ...defaultOptions };
    options.method = "GET";

    //const urlParams = Object.keys(params).length > 0 ? new URLSearchParams(params).toString() : ''
    const urlParams = queryString.stringify(params);
    const url = `${defaultUrl}${path}?${urlParams}`;

    return requestUtils.send(url, options, displayError);
};
requestUtils.post = async (path, data, displayError = false) => {
    const options = { ...defaultOptions };
    options.method = "POST";
    if (data instanceof FormData) {
        options.body = data;
        delete options.headers["Content-Type"];
    } else {
        options.body = JSON.stringify(data);
        options.headers["Content-Type"] = "application/json";
    }

    const url = `${defaultUrl}${path}`;

    return requestUtils.send(url, options, displayError);
};
requestUtils.put = async (path, data, displayError = false) => {
    const options = { ...defaultOptions };
    options.method = "PUT";
    options.body = JSON.stringify(data);
    options.headers["Content-Type"] = "application/json";

    const url = `${defaultUrl}${path}`;

    return requestUtils.send(url, options, displayError);
};
requestUtils.patch = async (path, data, displayError = false) => {
    const options = { ...defaultOptions };
    options.method = "PATCH";
    options.body = JSON.stringify(data);
    options.headers["Content-Type"] = "application/json";

    const url = `${defaultUrl}${path}`;

    return requestUtils.send(url, options, displayError);
};
requestUtils.delete = async (path, data, displayError = false) => {
    const options = { ...defaultOptions };
    options.method = "DELETE";
    options.body = JSON.stringify(data);
    options.headers["Content-Type"] = "application/json";

    const url = `${defaultUrl}${path}`;

    return requestUtils.send(url, options, displayError);
};

requestUtils.send = async (url, options, displayError) => {
    try {
        let resData = await fetch(url, options);

        let ok = resData.ok;
        resData = await resData.json();

        if (Array.isArray(resData.actions) && resData.actions.length > 0) {
            actionsWorker(resData.actions);
        }

        if (!ok) {
            displayError && toast(resData.message);

            return null;
        }
        return resData;
    } catch (err) {
        displayError && alert(err.message);
        return null;
    }
};

const ACTION_REDIRECT = "redirect";
const ACTION_LOGOUT = "logout";
const ACTION_NOTIFY = "notify";

const actionsWorker = (actions) => {
    actions.forEach((action) => {
        switch (action.type) {
            case ACTION_REDIRECT:
                window.location.href = action.to;
                break;
            case ACTION_LOGOUT:
                window.location.href = "/";
                break;
            case ACTION_NOTIFY:
                toast(action.message);
                break;
            default:
                console.log(action);
        }
    });
};

export default requestUtils;
