export const documentTypes = [
    {
        value: "PASSPORT",
        title: "Passport",
        translationKey: "account.verification.type.passport",
    },
    {
        value: "DRIVING_LICENSE",
        title: "Driver License",
        translationKey: "account.verification.type.driver_license",
    },
    {
        value: "ID_CARD",
        title: "ID Card",
        translationKey: "account.verification.type.id_card",
    },
    {
        value: "RESIDENCE_PERMIT",
        title: "Residence permit",
        translationKey: "account.verification.type.residence_permit",
    },
    // {
    //     value: 3,
    //     type: "Driver License 2",
    //     translationKey: "account.verification.driver_license_2",
    // },
    // {
    //     value: 4,
    //     type: "Selfie",
    //     translationKey: "account.verification.selfie",
    // },
    // {
    //     value: 5,
    //     type: "Bank Statement",
    //     translationKey: "account.verification.bank_statement",
    // },
    // {
    //     value: 6,
    //     type: "Utility Bill",
    //     translationKey: "account.verification.utility_bill",
    // },
    // {
    //     value: 8,
    //     type: "Other",
    //     translationKey: "account.verification.other",
    // },
    // {
    //     value: 9,
    //     type: "E-Wallet",
    //     translationKey: "account.verification.e_wallet",
    // },
];
